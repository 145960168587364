$hover-drop-shadow: 0.7vh;
$hover-margin-bottom: 0.8vh;
$hover-margin-top: 0.2vh;

$click-drop-shadow: 0.2vh;
$click-margin-bottom: 0.3vh;
$click-margin-top: 0.7vh;

$border-length: 0.3vh;
$drop-shadow: 0.9vh;

$dark-orange: #ba5a31;
$white: #ffffff;
$light-gray: #c5c6d0;
$gray: #a5a0b1;
$dark-gray: #62606d;
$darker-gray: #282c34;
$darkest-gray: #1b1b1f;

.AcronymPage {
  height: 100vh;
  width: 100vw;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .letters,
  .words {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .words {
    color: white;
    font-size: 5vh;
    margin: 5vh;
    cursor: pointer;
    border-bottom: solid 3px transparent;
    transition: border-bottom 0.15s ease-in-out;
    .locked {
      color: $dark-orange;
    }
    &:hover {
      border-bottom: solid 3px #ba5a31;
    }
  }

  .word {
    background-color: #282c34;
    z-index: 2;
  }

  .copied {
    font-size: 20px;
    margin-bottom: -9vh;
    margin-top: 9vh;
    color: #282c34;
    transition: margin-bottom 0.25s ease-in-out, margin-top 0.25s ease-in-out,
      color 0.15s ease-in-out;
    &.active {
      margin-bottom: -4vh;
      margin-top: 4vh;
      visibility: visible;
      color: #ba5a31;
      transition: margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
        color 0.25s ease-in-out;
    }
  }

  .letters {
    margin-left: 1.5vh;
    .cursor-wrapper {
      height: 12vh;
      .cursor {
        color: white;
        font-size: 5vh;
        line-height: 5vh;
        animation: blinkingText 1.2s infinite;
      }
    }
  }

  .generateButton {
    color: $darker-gray;
    font-size: 5vh;
    outline: none;
    border: none;
    background-color: $dark-orange;
    padding: 1vh 3vh;
    border-radius: 5vh;
    margin-bottom: 1vh;
    pointer-events: all !important;
    border-radius: 100px;
    border: solid $border-length $darker-gray;
    min-width: 16vw;
    padding: 1.4vh 1.7vh 1.4vh 1.7vh;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out,
      margin-top 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
    box-shadow: 0px $drop-shadow 0px $dark-orange;
    &:hover {
      cursor: pointer;
      box-shadow: 0px $hover-drop-shadow 0px $dark-orange;
      margin-bottom: $hover-margin-bottom;
      margin-top: $hover-margin-top;
      &:active {
        transition: border 0s ease-in-out, color 0s ease-in-out,
          margin-top 0s ease-in-out, box-shadow 0s ease-in-out,
          margin-bottom 0s ease-in-out, margin-top 0s ease-in-out,
          background-color 0s ease-in-out;
        box-shadow: 0px $click-drop-shadow 0px $dark-orange;
        margin-bottom: $click-margin-bottom;
        margin-top: $click-margin-top;
      }
    }

    &.generate {
      transition: border 0s ease-in-out, color 0s ease-in-out,
        margin-top 0s ease-in-out, box-shadow 0s ease-in-out,
        margin-bottom 0s ease-in-out, margin-top 0s ease-in-out,
        background-color 0s ease-in-out;
      box-shadow: 0px $click-drop-shadow 0px $dark-orange;
      margin-bottom: $click-margin-bottom;
      margin-top: $click-margin-top;
      &:focus {
        -webkit-tap-highlight-color: transparent !important;
        outline: none !important;
        touch-action: manipulation !important;
        touch-action: manipulation !important;
        -webkit-touch-callout: none !important;
        user-select: none !important;
        user-select: none !important;
        user-select: none !important;
        user-select: none !important;
        user-select: none !important;
      }
    }
  }
  .mobileInput {
    opacity: 0;
  }
}

@keyframes blinkingText {
  0% {
    color: white;
  }
  49% {
    color: white;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: white;
  }
}
