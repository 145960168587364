.LetterButton {
  button {
    color: white;
    font-size: 5vh;
    outline: none;
    border: none;
    background-color: transparent;
    &.lockable {
      cursor: pointer;
    }
  }
  div {
    color: white;
    font-size: 5vh;
    padding-left: 0.5vh;
    &.unlocked {
      visibility: hidden;
    }
  }
}
