@charset 'UTF-8';

@import './acronymPage';
@import './letterButton';

* {
  outline: 0;
  user-select: none;
  font-family: 'Droid Sans Mono', monospace;
}
